const defaultBoard = {
  name: "Default",
  columns: [
    {
      name: "Lead",
      list: [],
      description:
        "A potential prospect who could be interested and a good fit to partner with.",
      forecastCategory: "omitted",
      type: "open",
      probability: 0,
      id: "4b814122-44c9-4c8e-a857-7638950e6681",
    },
    {
      type: "open",
      description:
        "Determined that the prospect fits our ideal customer profile and to begin the sales process with.",
      forecastCategory: "pipeline",
      id: "43c61f2a-043b-4451-bd4b-223e22dc9369",
      list: [],
      name: "Sales Qualified",
      probability: 10,
    },
    {
      description:
        "The initial meeting happened and it’s the discovery phase where we ask questions to uncover need. This is more focused on seeing if there is a mutual ",
      id: "f336e1d9-5147-49fe-ab92-89da50914482",
      name: "Meeting Booked",
      probability: 20,
      forecastCategory: "pipeline",
      type: "open",
      list: [],
    },
    {
      list: [],
      forecastCategory: "best-case",
      probability: 40,
      description:
        "The prospect has narrowed their choices down to a few options (or chosen us). This is where we would get into a negotiation on closing the deal.",
      type: "open",
      name: "Negotiation",
      id: "7ad03764-cf48-468a-8324-144cb57ace37",
    },
    {
      id: "30c0f256-9444-47d4-86bf-50e6c0266465",
      name: "Contract Sent",
      forecastCategory: "best-case",
      type: "open",
      probability: 70,
      list: [],
      description:
        "We have delivered a proposal to the prospect with pricing as well as any required documentation that we need them to sign.",
    },
    {
      description: "Deal Closed Won",
      id: "faedc048-d75b-4652-8a9b-0b42d3489cb3",
      list: [],
      name: "Closed Won",
      type: "closed-won",
      forecastCategory: "closed",
      probability: 100,
    },
    {
      list: [],
      description: "Deal Closed Lost",
      forecastCategory: "closed",
      id: "12cc1145-8524-4628-a63c-e9f270eaacaa",
      type: "closed-lost",
      name: "Closed Lost",
      probability: 0,
    },
  ],
};

export default defaultBoard;

import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";

import { Board, BoardColummType, BoardItemCard } from "../models";

import bots from "./bots";
import { FirebaseSubscriber } from "./utils";
import { logError } from "../services/logging";
import audit from "./audit";
import chats from "./chats";
import defaultBoard from "../constants/defaultBoard";
import workflows from "./workflows";
import auth from "./auth";
import notifications from "./notifications";
import { NotificationType } from "../models/Notification";

class BoardsStore {
  private db: firebase.firestore.Firestore;
  private boardSubscriber?: FirebaseSubscriber;

  boards: Board[] | undefined;
  selectedBoard?: Board;
  selectedAssignee?: string;
  viewOnlyAccess: boolean = false;
  sortSelectedBoardByDesc: boolean = false;

  constructor() {
    this.db = firebase.firestore();

    makeAutoObservable(this);
  }

  loadBoards = async (botId: number) => {
    if (this.boardSubscriber?.id === botId) {
      return;
    }

    if (this.boardSubscriber) {
      this.boardSubscriber.unsubscribe();
      this.setBoards(undefined);
    }

    this.boardSubscriber = {
      id: botId,
      unsubscribe: this.db
        .collection("bots")
        .doc(`${botId}`)
        .collection("boards")
        .onSnapshot(
          (
            snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
          ) => {
            if (snap.empty) {
              this.createDefaultBoard();
              this.setBoards([]);
              return;
            }

            if (!snap.docs.filter((f) => !f.data().deletedAt).length) {
              this.createDefaultBoard();
            }

            let temp = this.boards?.slice() || [];

            snap.docChanges().forEach((change) => {
              const data = change.doc.data() as Board;

              switch (change.type) {
                case "added":
                  temp.push(data);
                  break;
                case "modified":
                  temp = temp.map((m) => (m.id === data.id ? data : m));
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            this.setBoards(temp);
          },
          logError
        ),
    };
  };

  selectBoard = (board?: Board) => {
    this.selectedBoard = board;
  };

  createBoard = async (
    board: Omit<Board, "id" | "updatedAt" | "createdAt">
  ): Promise<Board | null> => {
    if (!bots.selectedBot) {
      return null;
    }

    const doc = this.db
      .collection("bots")
      .doc(`${bots.selectedBot.id}`)
      .collection("boards")
      .doc();

    const data = {
      id: doc.id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      ...board,
    };

    await doc.set(data);

    audit.logEvent("board_created", {
      id: doc.id,
      doc: data,
      botId: bots.selectedBot.id,
    });

    return {
      ...data,
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
    };
  };

  updateBoard = async (
    board: Omit<Board, "updatedAt">
  ): Promise<Board | null> => {
    if (!bots.selectedBot) {
      return null;
    }

    const current = this.selectedBoard;
    const updated = {
      ...board,
      updatedAt: firebase.firestore.Timestamp.now(),
    };

    if (this.selectedBoard?.id === updated.id) {
      this.selectBoard(updated);
    }

    const doc = this.db
      .collection("bots")
      .doc(`${bots.selectedBot.id}`)
      .collection("boards")
      .doc(board.id);

    try {
      await doc.update({
        ...board,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      audit.logEvent("board_updated", {
        id: board.id,
        doc: board,
        botId: bots.selectedBot.id,
      });
    } catch (error) {
      if (this.selectedBoard?.id === updated.id) {
        this.selectBoard(current);
      }

      throw error;
    }

    return updated;
  };

  deleteBoard = async (id: string): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    const doc = this.db
      .collection("bots")
      .doc(`${bots.selectedBot.id}`)
      .collection("boards")
      .doc(id);

    await doc.update({
      deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    audit.logEvent("board_deleted", {
      id: id,
      botId: bots.selectedBot.id,
    });
  };

  sendChatToBoard = async (contactId: string, columnId: string) => {
    if (!this.selectedBoard) {
      return;
    }

    // await this.updateBoard({
    //   ...this.selectedBoard,
    //   columns: this.selectedBoard.columns.map((m) =>
    //     m.id === columnId
    //       ? { ...m, list: [...m.list, { id: contactId, type: "contact" }] }
    //       : m
    //   ),
    // });

    await chats.addChatLog(Number(contactId), {
      type: "move_board",
      data: { board: [this.selectedBoard.id] },
    });
  };

  addItemToBoard = async (item: BoardItemCard, columnId: string) => {
    if (!this.selectedBoard) {
      return;
    }

    if (item.comments?.length) {
      item.comments = await this.notifyMentionedUsers(item);
    }

    const currentColumn = this.selectedBoard?.columns?.find(
      (f) => f.id === columnId
    );
    const firstColumnId = this.selectedBoard.columns?.[0].id;

    if (columnId === firstColumnId) {
      item.openedAt = new Date() as any;
    }

    await chats.setAsContact(true);

    item.histories = [
      {
        createdBy: auth?.user?.id,
        currentStageId: columnId,
        addedAt: new Date() as any,
      },
    ];

    await this.updateBoard({
      ...this.selectedBoard,
      columns: this.selectedBoard.columns.map((m) =>
        m.id === columnId ? { ...m, list: [...m.list, item] } : m
      ),
    });

    await chats.saveContactData(Number(item.chat?.value), {
      dealBoardId: this.selectedBoard.id,
      assignee: item.assignee as any,
      dealId: item.id,
      dealStageType: currentColumn?.type,
    });

    await chats.addChatLog(Number(item.chat?.value), {
      type: "move_board",
      data: { board: [this.selectedBoard.id] },
    });

    await workflows.triggerWorkflowEvent("automation_pipeline", {
      chatId: item.chat?.value,
      boardId: this.selectedBoard.id,
      triggered_event: "assignee_changed",
    });
  };

  updateItemToBoard = async (
    item: BoardItemCard,
    columnId: string,
    oldColumn: string,
    currentAssignee?: string
  ) => {
    if (!this.selectedBoard) {
      return;
    }

    const currentColumn = this.selectedBoard?.columns?.find(
      (f) => f.id === columnId
    );

    if (item.comments?.length) {
      item.comments = await this.notifyMentionedUsers(item);
    }

    if (
      columnId !== oldColumn &&
      item.openedAt &&
      currentColumn?.type === BoardColummType.ClosedWon
    ) {
      item.closedAt = new Date() as any;
    }

    if (columnId !== oldColumn) {
      item.histories = [
        ...(item.histories?.map((m) => {
          if (!m.removedAt && m.currentStageId === oldColumn) {
            return {
              ...m,
              newStageId: columnId,
              removedAt: new Date() as any,
            };
          }
          return m;
        }) || []),
        {
          createdBy: auth?.user?.id,
          currentStageId: columnId,
          addedAt: new Date() as any,
        },
      ];
    }

    await this.updateBoard({
      ...this.selectedBoard,
      columns: this.selectedBoard.columns.map((m) => {
        if (columnId === oldColumn && m.id === columnId) {
          return {
            ...m,
            list: m.list.map((l) => (l.id === item.id ? item : l)),
          };
        } else {
          if (m.id === oldColumn) {
            return {
              ...m,
              list: m.list.filter((f) => f.id !== item.id),
            };
          }
          if (m.id === columnId) {
            return {
              ...m,
              list: [...m.list, item],
            };
          }
        }

        return m;
      }),
    });

    // TODO clear old chat assignee
    await chats.saveContactData(Number(item.chat?.value), {
      assignee: item.assignee as any,
      dealBoardId: this.selectedBoard.id,
      dealId: item.id,
      dealStageType: currentColumn?.type,
    });

    if (columnId !== oldColumn) {
      await workflows.triggerWorkflowEvent("automation_pipeline", {
        chatId: item.chat?.value,
        boardId: this.selectedBoard.id,
        fromColumnId: oldColumn,
        toColumnId: columnId,
      });
    }

    if (item.assignee !== currentAssignee) {
      await workflows.triggerWorkflowEvent("automation_pipeline", {
        chatId: item.chat?.value,
        boardId: this.selectedBoard.id,
        triggered_event: "assignee_changed",
      });
    }
  };

  notifyMentionedUsers = async (deal: BoardItemCard) => {
    if (!this.selectedBoard || !deal.comments?.length) {
      return;
    }
    const filter = deal.comments?.filter((f) => !f.notified);

    for (const comment of filter) {
      const userIds =
        comment.comment
          ?.match(/(@\[.*?\]\([^)]+\))/g)
          ?.map((f) => f.replace(/(@\[.*?\]\(|\))/g, "")) || [];

      const uniqueUserIds = Array.from(new Set(userIds));
      for (const userId of uniqueUserIds) {
        await notifications.createNotification(
          userId,
          NotificationType.DEAL_MENTION,
          {
            boardId: this.selectedBoard.id,
            deal,
            comment,
          }
        );
      }
    }

    return deal.comments.map((f) => {
      f.notified = true;
      return f;
    });
  };

  deleteItemFromBoard = async (item: BoardItemCard, columnId: string) => {
    if (!this.selectedBoard) {
      return;
    }

    await this.updateBoard({
      ...this.selectedBoard,
      columns: this.selectedBoard.columns.map((m) => {
        if (m.id === columnId) {
          return {
            ...m,
            list: m.list.filter((f) => f.id !== item?.id),
          };
        }

        return m;
      }),
    });

    await chats.saveContactData(Number(item.chat?.value), {
      dealBoardId: "",
      assignee: "",
      dealId: "",
      dealStageType: "",
    });

    await chats.addChatLog(Number(item.chat?.value), {
      type: "remove_board",
      data: { board: [this.selectedBoard.id] },
    });
  };

  setAssignee = (assignee: string) => {
    this.selectedAssignee = assignee;
  };

  setViewOnlyAccess = (viewOnlyAccess: boolean) => {
    this.viewOnlyAccess = viewOnlyAccess;
  };

  private setBoards = (boards?: Board[]) => {
    this.boards = boards?.filter((f) => !f.deletedAt);
  };

  private createDefaultBoard = async () => {
    if (!bots.selectedBot) {
      return;
    }

    const board = await this.createBoard(defaultBoard as any);

    if (board) {
      this.selectBoard(board);
    }
  };

  setSortSelectedBoardByDesc = (bool: boolean) => {
    this.sortSelectedBoardByDesc = bool;
  };
}

export default new BoardsStore();
